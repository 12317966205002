<template>
  <div v-if="currentPatient">
    <el-table
        :data="currentPatient.ecog"
        :default-sort = "{prop: 'date', order: 'descending'}"
        style="width: 100%"
        stripe>
      <el-table-column
          prop="date"
          label="Datum"
          sortable>
        <template #default="scope">
          {{ $filters.germanDate(scope.row.date) }}
        </template>
      </el-table-column>
      <el-table-column
          prop="cycle"
          label="Zyklus"
          sortable>
      </el-table-column>
      <el-table-column
          prop="score"
          label="ECOG"
          sortable>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import socket from './../socket'

export default {
  name: "PatientEcog",
  props: [],
  components: {
  },
  mounted: function () {

    socket.on('form-finished', async () => {
      this.reloadCurrentPatient()
    })

  },
  computed: {
    ...mapGetters('patients', ['currentPatient'])
  },
  /*
  watch: {
    var: function (val) {
    }
  },
  */
  methods: {
    ...mapActions('patients', ['reloadCurrentPatient'])
  }
}
</script>
<style scoped>
</style>
